@import "../utilities/mixins"

.is-clearfix
  +clearfix

.is-pulled-left
  float: left !important

.is-pulled-right
  float: right !important
