@import "../utilities/mixins"

$section-padding: 3rem 1.5rem !default
$section-padding-desktop: 3rem 3rem !default
$section-padding-medium: 9rem 4.5rem !default
$section-padding-large: 18rem 6rem !default

.section
  padding: $section-padding
  // Responsiveness
  +desktop
    padding: $section-padding-desktop
    // Sizes
    &.is-medium
      padding: $section-padding-medium
    &.is-large
      padding: $section-padding-large
